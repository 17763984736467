<template>
  <div class="firfin_layer" @click.self="onClickClose()">
    <div class="inner_layer_comm inner_layer_term">
      <div class="area_cont">
        <div class="layer_head">
          <h3 class="tit_layer">아이폰 출시 알림 받기 <span class="txt_break_mb">(<em class="tc_primary">4월</em> 출시 예정)</span></h3>
        </div>
        <div class="layer_body">
          <h4>개인정보 수집 및 이용 동의</h4>
          <p>(주)레몬트리는 아래의 목적으로 개인정보를 수집 및 이용하며, 개인정보를 안전하게 <br>취급하는데 최선을 다합니다.</p>
          <ol>
            <li>
              <p>수집목적</p>
              <ul>
                <li>서비스 출시 안내</li>
                <li>서비스 관련 광고성 정보 안내</li>
              </ul>
            </li>
            <li>
              <p>수집 항목</p>
              <ul>
                <li>이메일주소, 휴대폰번호</li>
              </ul>
            </li>
            <li>
              <p>보유 기간</p>
              <ul>
                <li>서비스 출시 안내 이후 고객 문의 대응을 위해 출시일 기준 6개월 보유 후 즉시 파기</li>
              </ul>
            </li>
            <li>
              <p>개인정보 처리업무 위탁</p>
              <ul>
                <li>(주)레몬트리는 원칙적으로 고객의 개인정보를 수집 목적 범위 내에서 처리하며, 제 3자에게 고객의 사전 동의 없이 본래 범위를 초과하여 처리하거나 제공하지 않습니다.</li>
                <li>다만 다음의 경우에는 개인정보 처리업무를 위탁하고 있습니다.
                  <table>
                    <colgroup>
                      <col style="width:40%">
                      <col style="width:60%">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>위탁업체</th>
                        <th>위탁업무 내용</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>주식회사 다우기술(뿌리오)</td>
                        <td>
                          <ul>
                            <li>위탁 업무 : 문자 메시지 발송</li>
                            <li>위탁 항목 : 휴대폰번호</li>
                            <li>보유 기간 : 서비스 출시일 기준 6개월 보유 후 즉시 파기</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>스티비 주식회사</td>
                        <td>
                          <ul>
                            <li>위탁 업무 : 이메일 발송</li>
                            <li>위탁 항목 : 이메일주소</li>
                            <li>보유 기간 : 서비스 출시일 기준 6개월 보유 후 즉시 파기</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="layer_foot">
          <div class="inner_foot">
            <p class="desc_point">동의 거부 시 서비스 출시 알림 및 정보 안내를 받으실 수 없어요!</p>
            <div class="tf_alarm">
              <input
                type="text"
                placeholder="이메일주소 또는 휴대폰"
                v-model="viewModel.alarmData"
                @input="viewModel.onUpdateAlarmData()"/>
            </div>
            <CheckboxOnlyOne
              id="agree"
              text="동의하고 알림받기"
              :value.sync="viewModel.alarmtermAgree"/>
            <button
              class="btn_compelete"
              :disabled="isDisabeldCompeleteBtn"
              @click="onClickComplete()">알림 등록하기</button>
          </div>
        </div>
        <button
          class="btn_close_cross"
          @click="onClickClose()">
          <IconSvg iconName="cross" :size="24" iconColor="#232323"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxOnlyOne from '@/components/common/checkbox/CheckboxOnlyOne';
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name:'PopupIosAlarm',
  props:{
    viewModel: Object
  },
  components:{
    CheckboxOnlyOne,
    IconSvg
  },
  data(){
    return{
      isAgree: false,
    }
  },
  mounted(){
    document.body.style.overflow = "hidden";
  },
  unmounted(){
    document.body.style.overflow = "";
  },
  computed:{
    isDisabeldCompeleteBtn(){
      return !this.viewModel.alarmtermAgree || !this.viewModel.alarmData
    }
  },
  methods:{
    onClickClose(){
      document.body.style.overflow = "";
      this.$emit('onClickClose');
    },
    onClickComplete(){
      document.body.style.overflow = "";
      this.$emit('onClickComplete');
    }
  }
}
</script>
<style scoped>
body{overflow:hidden}
.firfin_layer{position:fixed;top:0;bottom:0;left:0;right:0;z-index:100;padding:20px;background-color:rgba(0,0,0,.6);text-align:center}
.firfin_layer:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.firfin_layer .inner_layer_term{display:inline-block;overflow:hidden;position:relative;width:100%;max-width:1036px;height:100%;max-height:1002px;padding-left:309px;border-radius:4px;background:#BEA9ED url(/assets/images/main/img_term_popup.png) no-repeat 10px center;background-size:289px 289px;white-space:normal;vertical-align:middle;box-sizing:border-box}

.area_img{float:left;width:309px;height:470px;}

.area_cont{display:flex;flex-direction:column;max-height:100%;padding:38px 140px 38px 24px;background-color:#fff;text-align:left;box-sizing:border-box}
.layer_head{flex:0 0 30px}
.layer_head .tit_layer,
.layer_head .tit_layer .tc_primary{font-weight:700;font-size:24px;line-height:30px;color:#111}
.layer_head .tc_primary{display:inline-block;color:#8F69DD !important;vertical-align:top}
.layer_body h4{font-weight:600;font-size:16px;line-height:24px;color:#111}
.layer_body h4 +p{margin-top:16px}
.layer_body,
.layer_body ol,
.layer_body table,
.layer_foot .tf_alarm,
.layer_foot .check_comm,
.layer_foot .btn_compelete{margin-top:24px}
.layer_body{flex:0 1;overflow-y:auto;font-weight:500;font-size:14px;line-height:22px;color:#111}
.layer_body ol{padding-left:16px;font-size:12px;line-height:18px;word-break:keep-all;vertical-align:top;color:#111}
.layer_body ol,
.layer_body ol > li{list-style:decimal}
.layer_body ul{padding-left:16px;text-indent:-18px}
.layer_body ul > li:before{display:inline-block;width:4px;height:4px;margin:6px 7px 8px;border-radius:100%;background-color:#111;vertical-align:top;content:''}
/* .layer_body ul,
.layer_body ul > li{list-style:disc}
.layer_body ol > li > ul{padding-left:16px} */
.layer_body table{width:calc(100% + 32px);margin-left:-32px;border-collapse:collapse;border-spacing:0;border:1px solid #E7E5E5;table-layout:fixed;}
.layer_body table th,
.layer_body table td{padding:8px;font-size:12px;line-height:18px;border:1px solid #E7E5E5;color:#111;word-wrap:break-word;vertical-align:top}
.layer_body table th{font-weight:500;background-color:#E7E5E5}


.layer_foot{flex:0 0;margin-top:50px;padding-top:50px;text-align:right;border-top:1px solid #8F69DD}
.layer_foot .desc_point{font-weight:600;color:#8F69DD;text-align:left}


.layer_foot .tf_alarm{width:100%;position:relative;height:56px;border-radius:4px;border:1px solid #E7E5E5;font-size:16px;line-height:26px;box-sizing:border-box}
.layer_foot .tf_alarm:focus-within{border-color:#000}
.layer_foot .tf_alarm input{display:block;width:100%;height:100%;padding:14px 25px;border:0 none;outline:none;font-weight:600;font-size:16px;background:none;color:#8F69DD;box-sizing:border-box}
.layer_foot .tf_alarm input::-webkit-input-placeholder{color:#999999}
.layer_foot .tf_alarm input:-moz-placeholder{color:#999999}
.layer_foot .tf_alarm input::-moz-placeholder{color:#999999}
.layer_foot .tf_alarm input:-ms-input-placeholder{color:#999999}

.layer_foot .check_comm{text-align:left}
.layer_foot .btn_compelete{display:inline-block;width:180px;height:56px;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;background-color:#232323;color:#fff;vertical-align:top}
.layer_foot .btn_compelete:disabled{background-color:#E7E5E5;color:#C4C2C2}

.btn_close_cross{position:absolute;top:35px;right:29px;padding:3px}

/* 모바일 */
@media all and (max-width:700px){
  .firfin_layer .inner_layer_term{padding:0;background:none}
  .area_cont{padding:20px}
  .layer_head .tit_layer,
  .layer_head .tit_layer .tc_primary{font-size:20px}
  .layer_body,
  .layer_body ol,
  .layer_body table,
  .layer_foot .tf_alarm,
  .layer_foot .check_comm,
  .layer_foot .btn_compelete{margin-top:16px}
  .layer_body{font-size:12px;line-height:18px}
  .layer_body p br{display:none}
  .layer_foot{margin-top:16px;padding-top:16px}
  .layer_foot .desc_point{font-size:14px;line-height:22px}
  .layer_foot .btn_compelete{display:block;width:100%}
  .btn_close_cross{top:20px;right:20px}
}
</style>