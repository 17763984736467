<template>
  <SectionBasic
    id="guide"
    :isActive="isActive">
    <div class="group_half">
      <div class="half_right area_middle">
        <div class="inner_middle">
          <h3 class="tit_section">은행 방문 없이<br>만들 수 있는 용돈카드</h3>
          <p class="desc_section">용돈기입장은 작심삼일.<br>아이의 용돈생활을 자동으로 기록, 관리, 분석해서 똑똑한 소비습관을 <span class="txt_break_response">만들어주세요.</span></p>
        </div>
      </div>
      <div class="half_left">
        <div class="area_img">
          <div class="inner_img"></div>
        </div>
      </div>
    </div>
    <ol class="list_section">
      <li>만 7세 이상 본인명의 휴대폰으로 가입</li>
      <li>카드 뒷면 자녀 실명 표기</li>
      <li>자녀의 카드사용내역 및 잔액 실시간 조회</li>
      <li>부모가 카드신청/분실신고/해지 가능</li>
      <li>온라인 결제, 간편페이 결제 가능</li>
      <li>KB국민카드 전가맹점 결제(청소년유해시설 제외)</li>
    </ol>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionGuide',
  props:{
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_guide{padding:120px 0 124px;background-color:#fff}
.section_guide .tit_section{font-weight:700;font-size:48px;line-height:64px;color:#111}
.section_guide .desc_section{font-size:20px;line-height:30px}
.section_guide .group_half{padding-top:50%}
.section_guide .half_left{padding:0 20px 0 0}
.section_guide .area_img .inner_img{height:100%;background:url(/assets/images/main/img_guide.jpg) no-repeat 0 0;background-size:contain}
.section_guide .area_middle{padding-left:60px}
.section_guide .list_section{margin-top:60px}

/* 모바일 */
@media all and (max-width:1199px){
  .section_guide{padding:48px 0}
  .section_guide .tit_section{font-size:28px;line-height:42px}
  .section_guide .group_half{padding-top:0}
  .section_guide .half_left{margin-top:24px;padding:0}
  .section_guide .area_img{position:static;max-width:640px}
  .section_guide .area_img .inner_img{padding-top:100%;background:url(/assets/images/main/img_guide_mo.jpg) no-repeat 0 0;background-size:contain;}
  .section_guide .area_middle{padding:0}
  .section_guide .list_section{margin-top:24px}
}
</style>