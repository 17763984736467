<template>
  <SectionBasic
    id="top"
    :isActive="isActive">
    <div class="cont_top">
      <h2 class="tit_top">용돈은<br> 퍼핀으로 주세요</h2>
      <p class="desc_top">엄마가 만들어주는 초등용돈카드</p>
      <div class="area_download">
        <template v-if="viewModel.isMobile">
          <button
            class="btn_download"
            @click="viewModel.onClickDownload()">
            <div class="inner_button">
              <p class="txt_btn_download">퍼핀 앱에서 카드 신청하기</p>
              <IconSvg
              iconName="page_next"
              iconColor="#8F69DD"
              :size="12"/>
            </div>
          </button>
        </template>
        <template v-else>
          <button
            class="btn_download_store btn_download_app_store ir_pm"
            @click="viewModel.onClickDownload('IOS')">App Store</button>
          <button
            class="btn_download_store btn_download_google_store ir_pm"
            @click="viewModel.onClickDownload('ANDROID')">Google Play</button>
        </template>
      </div>
    </div>
    <div class="area_img">
      <div class="bg_top"></div>
      <img class="img_card" src="/assets/images/main/img_top_card.gif" alt="">
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import IconSvg from '@/components/common/icon/IconSvg.vue';

export default {
  name:'SectionTop',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
    IconSvg,
  },
}
</script>

<style scoped>
.section_top{background-color:#BEA9ED}
.section_top >>> .inner_section{position:relative;padding:313px 20px 233px}
.section_top .cont_top{position:relative;z-index:3}
.section_top .tit_top{font-weight:700;font-size:66px;line-height:88px;color:#111}
.section_top .desc_top{margin-top:40px;font-weight:700;font-size:28px;line-height:36px;color:#111}
.section_top .area_img{position:absolute;top:160px;right:20px;z-index:1;width:670px;height:700px}
.section_top .img_card{position:absolute;top:102px;left:-327px;z-index:2;width:960px;height:540px}
.section_top .bg_top{width:100%;height:100%;background:url(/assets/images/main/img_top.png) no-repeat top 0 right 0;background-size:auto 100%}

.area_download{overflow:hidden;margin-top:60px}

.btn_download_app_store{background-image:url(/assets/images/main/img_top_app_store.png)}
.btn_download_google_store{background-image:url(/assets/images/main/img_top_google_store.png)}
.area_download .btn_download_store{display:inline-block;width:180px;height:58px;background-repeat:no-repeat;background-size:contain;vertical-align:top}
.area_download .btn_download_store + .btn_download_store{margin-left:16px}


.toast_comm{display:block;position:absolute;top:-80px;width:100%;height:80px;padding:14px 0 14px 90px;border-radius:10px;box-sizing:border-box;opacity:0;transition:.4s}
.toast_comm.active{top:-107px;opacity:1}
.toast_comm .icon_lemontree{position:absolute;top:10px;left:20px}
.toast_comm .inner_toast{height:52px;font-size:0;white-space:nowrap}
.toast_comm .inner_toast:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.toast_comm .inner_toast .txt_message{display:inline-block;font-weight:bold;font-size:16px;line-height:26px;color:#fff;letter-spacing:-0.5px;vertical-align:middle;white-space:normal}
.toast_success{background-color:#111}
.toast_error{background-color:#F04848}

/* 모바일 */
@media all and (max-width:1199px){
  .section_top >>> .inner_section{overflow:hidden;padding:70px 0 16px}
  .section_top .cont_top{padding:0 20px 16px;z-index:20}
  .section_top .tit_top{font-size:40px;line-height:48px}
  /* .section_top .tit_top br{display:none} */
  .section_top .desc_top{margin-top:8px;font-size:16px;line-height:24px}
  .section_top .area_img{position:relative;top:inherit;right:inherit;width:auto;height:240px}
  .section_top .img_card{position:absolute;top:35px;left:50%;z-index:2;width:328px;height:auto;margin-left:-241px}
  .section_top .bg_top{width:100%;height:100%;background:url(/assets/images/main/img_top.png) no-repeat center 0;background-size:auto 100%}

  .area_download{margin-top:0}


  .area_download .btn_download{position:fixed;bottom:0;left:0;right:0;z-index:20;width:100%;background-color:#232323;box-sizing:border-box;text-align:center}
  .area_download .btn_download .inner_button{padding:23px 0}
  .area_download .txt_btn_download{
    display:inline-block;vertical-align:top;
    font-weight:700;font-size:20px;line-height:24px;color:#fff;
    background: linear-gradient(
        to right,
        #FFFFFF 20%,
        #D8CBF4 30%,
        #bea9ed 60%,
        #8F69DD 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
  }
  .area_download .btn_download .icon_page_next{margin:7px 0 0 5px}

  .area_download .btn_download .icon_android{margin-left:2px}
  .area_download .btn_download .icon_ios{margin-left:7px}

  .toast_comm{top:-72px;height:72px;padding:10px 0 10px 67px}
  .toast_comm.active{top:-99px}
  .toast_comm .icon_lemontree{top:16px;left:18px}
}

@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
</style>