import scrolled from '@/mixins/Scrolled'
import { mapGetters } from 'vuex';

const ShowSection = {
  mixins:[scrolled],
  beforeMount(){
    const sectionQuery = this.$route.query.section;
    this.sectionQuery = sectionQuery;
  },
  mounted(){
    this.setMobile();
    this.setSectionTop();
    this.showSection();
  },
  watch:{
    '$route.query.section':{
      deep:true,
      handler(newVal, oldVal) {
        if(newVal){
          this.goToSection(newVal,true)
        }
      }
    },
    bodyHeight(){
      this.setSectionTop();
      if(!this.isLoaded){
        this.isLoaded = true;
      }
    },
    getWindowWidth(){
      this.setMobile();
      this.setSectionTop();
    },
    getWindowHeight(){
      this.setSectionTop();
      this.showSection();
    },
    scrollTop(){
      this.showSection();
    },
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowWidth', 'getWindowHeight', 'getIsMobile' ]),
  },
  data(){
    return{
      isLoaded : false,
      sectionQuery:null,
      showPoint : 0.8, // 섹션 fadein 되어지는 섹션 화면노출 비율 기준점
    }
  },
  methods: {
    setMobile(){
      this.viewModel.isMobile = this.breakPoint ? ( this.getWindowWidth <= this.breakPoint ) : this.getIsMobile
    },
    setSectionTop(){
      this.$nextTick(() => {
        const sectionElementList = document.getElementsByClassName('section_comm');
        // const contactElement = document.getElementsByClassName('foot_firfin')[0];
        // const contactElementTop = contactElement ? contactElement.offsetTop : null;
        Array.from(sectionElementList).forEach(section => {
          const id = section.id.replace('section','').toLowerCase();
          const top = section.offsetTop;
          this.sectionsTop[id].top = top;
        });
        if(this.sectionQuery){
          this.goToSection(this.sectionQuery,false)
        }
      });
    },
    showSection(){
      this.$nextTick(() => {
        const arraySections = Object.values(this.sectionsTop);
        arraySections.forEach( section => {
          if(section.top <= this.scrollTop + ( this.getWindowHeight * this.showPoint ) && !section.show){
            this.sectionsTop[section.id].show = true;
          }
        })
      });
    },
    goToSection(clickedId, isSmooth){
      const id = clickedId.toLowerCase()
      const sectionTop = this.sectionsTop[id].top;
      const headerHeight = this.viewModel.isMobile ? 54 : 80;
      if(sectionTop != null && sectionTop >= 0){
        let scrollObj = {
          top: sectionTop - headerHeight,
        }
        if(isSmooth){
          scrollObj.behavior = 'smooth';
        }
        window.scrollTo(scrollObj);
        this.$router.replace({'query': {}}).catch(()=>{});
        this.sectionQuery = null;
      }
    }
  }
}
export default ShowSection;