export default class MainModel {
  constructor(){
    this.reviewDataList = [
      {
        review:'“초4 아이 현금 주기 번거로웠는데,<br>용돈전용카드라서 바로 신청했어요”',
        reviewFrom:'GOOGLE_PLAY',
        reviewScore:5
      },
      {
        review:'“내 아이의 용돈관리가<br>쉬워졌어요!”',
        reviewFrom:'INSTAGRAM',
        reviewScore:5
      },
      {
        review:'“세 아이 모두 쓰고 있는데<br>완전 편해요”',
        reviewFrom:'GOOGLE_PLAY',
        reviewScore:5
      },
      {
        review:'“교통카드까지 되니까<br>대박!”',
        reviewFrom:'INSTAGRAM',
        reviewScore:5
      },
      {
        review:'“아이도 자기 카드가 생겼다고<br>너무 좋아합니다”',
        reviewFrom:'GOOGLE_PLAY',
        reviewScore:5
      },
      {
        review:'“연령에 따른 평균용돈금액을<br>알려줘서 수월하게 정했어요”',
        reviewFrom:'NAVER_MOM_CAFE',
        reviewScore:5
      },
      {
        review:'“아이 용돈관리, 소비습관<br>한 번에 잡기 좋은 서비스에요”',
        reviewFrom:'GOOGLE_PLAY',
        reviewScore:5
      },
      {
        review:'“퍼핀카드로 바꾸니<br>아이가 정말 좋아해요”',
        reviewFrom:'NAVER_MOM_CAFE',
        reviewScore:5
      },
      {
        review:'“용돈계약과 퀴즈를 통해<br>보너스용돈을 받는 것이 신선합니다”',
        reviewFrom:'GOOGLE_PLAY',
        reviewScore:5
      },
    ];
    this.appDataList = [
      {
        img:'img_app_slide4',
        text:''
      },
      {
        img:'img_app_slide5',
        text:'부모앱'
      },
      {
        img:'img_app_slide1',
        text:'부모/자녀 공통화면'
      },
      {
        img:'img_app_slide2',
        text:'자녀앱'
      },
      {
        img:'img_app_slide3',
        text:''
      },
    ];
  }
}