<template>
  <PageWithLayout>
    <SectionTop
      :isActive="sectionsTop.top.show"
      :viewModel="viewModel" />
    <!-- <SectionComing
      :isActive="sectionsTop.coming.show" /> -->
    <SectionReview
      :isActive="sectionsTop.review.show"
      :viewModel="viewModel" />
    <SectionGuide
      :isActive="sectionsTop.guide.show" />
    <SectionCard
      :isActive="sectionsTop.card.show"
      :viewModel="viewModel"
      :scrollTop="scrollTop"
      :sectionTop="sectionsTop.card.top"
      :sectionBottom="sectionsTop.app.top" />
    <SectionApp
      :isActive="sectionsTop.app.show"
      :viewModel="viewModel" />
    <SectionFinance
      :isActive="sectionsTop.finance.show"
      :viewModel="viewModel" />
    <SectionContent
      :isActive="sectionsTop.content.show"
      :viewModel="viewModel" />
    <SectionWorld
      :isActive="sectionsTop.world.show"
      :viewModel="viewModel" />
    <SectionReward
      :isActive="sectionsTop.reward.show"
      :viewModel="viewModel" />
    <SectionPartner
      :isActive="sectionsTop.partner.show"
      :viewModel="viewModel" />
    <SectionSafe
      :isActive="sectionsTop.safe.show" />
    <SectionOutro
      :isActive="sectionsTop.outro.show"
      :viewModel="viewModel" />

    <!-- <PopupTerm
      v-if="viewModel.isPopupTerm"
      @onClickClose="viewModel.onClickCloseTermPopup()"
      @onClickComplete="viewModel.onClickAlarmSubmit()"/> -->
    <PopupIosAlarm
      v-if="viewModel.isPopupIosAlarm"
      :viewModel="viewModel"
      @onClickClose="viewModel.onClickClosePopupIosAlarm()"
      @onClickComplete="viewModel.onClickAlarmSubmit()"/>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
// components
import SectionTop from '@/views/main/view/components/top/SectionTop';
// import SectionComing from '@/views/main/view/components/coming/SectionComing';
import SectionReview from '@/views/main/view/components/review/SectionReview';
import SectionGuide from '@/views/main/view/components/guide/SectionGuide';
import SectionCard from '@/views/main/view/components/card/SectionCard';
import SectionApp from '@/views/main/view/components/app/SectionApp';
import SectionFinance from '@/views/main/view/components/finance/SectionFinance';
import SectionContent from '@/views/main/view/components/content/SectionContent';
import SectionWorld from '@/views/main/view/components/world/SectionWorld';
import SectionReward from '@/views/main/view/components/reward/SectionReward';
import SectionPartner from '@/views/main/view/components/partner/SectionPartner';
import SectionSafe from '@/views/main/view/components/safe/SectionSafe';
import SectionOutro from '@/views/main/view/components/outro/SectionOutro';
// popup
// import PopupTerm from '@/views/main/view/popup/PopupTerm.vue';
import PopupIosAlarm from '@/views/main/view/popup/PopupIosAlarm.vue';
// viewModel
import MainViewModel from '@/views/main/viewModel/MainViewModel';

import showSection from '@/mixins/ShowSection'



export default {
  name:'Main',
  mixins:[showSection],
  components:{
    PageWithLayout,

    SectionTop,
    // SectionComing,
    SectionReview,
    SectionGuide,
    SectionCard,
    SectionApp,
    SectionFinance,
    SectionContent,
    SectionWorld,
    SectionReward,
    SectionPartner,
    SectionSafe,
    SectionOutro,

    // PopupTerm,
    PopupIosAlarm,
  },
  // mounted(){
  //   if(this.$route.query.downloadType && this.$route.query.downloadType === 'iosPopup'){
  //     this.viewModel.isPopupIosAlarm = true;
  //     this.$router.replace({'query': {}}).catch(()=>{});
  //   }
  // },
  data(){
    return{
      viewModel: new MainViewModel(),
      sectionsTop:{
        top : { id:'top', top:0, show:true },
        // coming: { id:'coming', top:null, show:true },
        review: { id:'review', top:null, show:true },
        guide: { id:'guide', top: null, show:false },
        card: { id:'card', top:null, show:false },
        app: { id:'app', top:null, show:false },
        finance: { id:'finance', top:null, show:false },
        content: { id:'content', top:null, show:false },
        world: { id:'world', top:null, show:false },
        reward: { id:'reward', top:null, show:false },
        partner: { id:'partner', top:null, show:false },
        safe: { id:'safe', top:null, show:false },
        outro: { id:'outro', top:null, show:false },
      }
    }
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "용돈은 퍼핀으로 주세요. 엄마가 만들어주는 초등용돈카드. 퍼핀 앱 하나로 가족이 함께 관리해요. 자녀의 소비, 저축, 투자교육까지 퍼핀과 함께 즐겁고 안전한 가족 금융을 시작해보세요.",
        vmid:"description",
      },
      {
        property: "og:description",
        content: "용돈은 퍼핀으로 주세요. 엄마가 만들어주는 초등용돈카드. 퍼핀 앱 하나로 가족이 함께 관리해요. 자녀의 소비, 저축, 투자교육까지 퍼핀과 함께 즐겁고 안전한 가족 금융을 시작해보세요.",
        vmid:"og:description",
      },
      {
        name: "twitter:description",
        content: "용돈은 퍼핀으로 주세요. 엄마가 만들어주는 초등용돈카드. 퍼핀 앱 하나로 가족이 함께 관리해요. 자녀의 소비, 저축, 투자교육까지 퍼핀과 함께 즐겁고 안전한 가족 금융을 시작해보세요.",
        vmid:"twitter:description",
      },
    ],
  },
}
</script>
<style scoped>
/* common */
.section_comm >>> .badge_info + .tit_section,
.section_comm >>> .badge_info + .tit_world,
.section_comm >>> .txt_section + .tit_section,
.section_comm >>> .tit_section + .desc_section,
.section_comm >>> .tit_section + .list_section,
.section_comm >>> .desc_section + .list_section,
.section_comm >>> .list_section + .desc_section{display:block;margin-top:24px}

.section_comm >>> .badge_info{display:block}
.section_comm >>> .badge_info .icon_badge_world{background-image:url(/assets/images/main/icon_badge_world.png)}
.section_comm >>> .badge_info .icon_badge_reward{background-image:url(/assets/images/main/icon_badge_reward.png)}
.section_comm >>> .badge_info .icon_badge_safe{background-image:url(/assets/images/main/icon_badge_safe.png)}
.section_comm >>> .badge_info .icon_badge{display:inline-block;width:40px;height:40px;background-size:40px;background-repeat:no-repeat;vertical-align:top}
.section_comm >>> .badge_info .txt_badge{display:inline-block;margin:8px 0 8px 12px;font-weight:700;font-size:24px;line-height:24px;color:#8F69DD;vertical-align:top}

.section_comm >>> .desc_section{font-weight:500;font-size:20px;line-height:30px}

.section_comm >>> ul.list_section li{font-weight:500;font-size:20px;line-height:30px;word-wrap:break-word;word-break:keep-all}
.section_comm >>> ul.list_section li:before{display:inline-block;width:4px;height:4px;margin:13px;border-radius:100%;background-color:#666;vertical-align:top;content:''}
.section_comm >>> ol.list_section{counter-reset:li}
.section_comm >>> ol.list_section li{font-weight:600;font-size:24px;line-height:30px;color:#333;word-wrap:break-word;word-break:keep-all}
.section_comm >>> ol.list_section li:before{display:inline-block;width:32px;height:32px;margin-right:16px;padding-top:1px;border-radius:16px;font-family:'Poppins';font-weight:600;font-size:18px;line-height:30px;background-color:#BEA9ED;color:#232323;vertical-align:top;text-align:center;content:counter(li);counter-increment: li;box-sizing:border-box}
.section_comm >>> ol.list_section li + li{margin-top:32px}

@media all and (max-width:1199px){
  /* common */
  /* .section_comm >>> .badge_info + .tit_section,
  .section_comm >>> .badge_info + .tit_world,
  .section_comm >>> .txt_section + .tit_section,
  .section_comm >>> .tit_section + .desc_section,
  .section_comm >>> .tit_section + .list_section,
  .section_comm >>> .desc_section + .list_section,
  .section_comm >>> .list_section + .desc_section{margin-top:32px} */
  
  .section_comm >>> .desc_section{font-weight:500;font-size:18px;line-height:27px;word-wrap:break-word;word-break:keep-all}

  .section_comm >>> ul.list_section li{padding-left:27px;font-size:18px;line-height:27px;text-indent:-27px}
  .section_comm >>> ul.list_section li:before{display:inline-block;width:4px;height:4px;margin:12px 11px 11px;border-radius:100%;background-color:#666;vertical-align:top;content:''}

  .section_comm >>> ol.list_section li{padding-left:40px;font-size:18px;line-height:24px;text-indent:-40px}
  .section_comm >>> ol.list_section li:before{width:24px;height:auto;padding-top:2px;border-radius:12px;font-size:16px;line-height:22px;text-indent:0}
  .section_comm >>> ol.list_section li + li{margin-top:24px}
}
</style>