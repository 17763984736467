<template>
  <SectionBasic
    id="reward"
    :isActive="isActive">
    <div class="area_flex">
      <div class="item_flex1">
        <template v-if="viewModel.isMobile">
          <span class="badge_info">
            <span class="icon_badge icon_badge_reward"></span>
            <span class="txt_badge">학습과 보상</span>
          </span>
          <h3 class="tit_world">매일 꾸준히 금융상식을 쌓도록 학습 보상을 챙겨줘요</h3>
        </template>
        <div class="area_img">
          <div class="inner_img"></div>
        </div>
        <h3 v-if="!viewModel.isMobile" class="tit_world">매일 꾸준히 금융상식을 쌓도록 학습 보상을 챙겨줘요</h3>
      </div>
      <div class="item_flex2 area_middle">
        <div class="inner_middle">
          <p v-if="viewModel.isMobile" class="desc_world">퀴즈를 풀면 보상이 주어져요.<br><span class="txt_break_mb">과몰입 방지를 위해 1일 5문제만 풀이 가능하며, <br>부모님의 설정에 따라 보상을 추가 지급할 수 있어요.</span></p>
          <div class="area_img">
            <div class="inner_img"></div>
          </div>
          <p v-if="!viewModel.isMobile" class="desc_world">퀴즈를 풀면 보상이 주어져요.<br><span class="txt_break_mb">과몰입 방지를 위해 1일 5문제만 풀이 가능하며, <br>부모님의 설정에 따라 보상을 추가 지급할 수 있어요.</span></p>
        </div>
      </div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionReward',
  props:{
    isActive: Boolean,
    viewModel: Object,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_reward{padding:80px 0 60px;background-color:#E2D7C4}

.section_reward .area_flex{display:flex;align-items:stretch}

.section_reward .item_flex1{position:relative;width:60%;padding-right:40px;box-sizing:border-box}
.section_reward .item_flex1 .area_img .inner_img{padding-top:133%;background:url(/assets/images/main/img_reward.jpg)no-repeat 0 0;background-size:contain}
.section_reward .item_flex1 .tit_world{margin-top:40px;font-weight:700;font-size:48px;line-height:64px;color:#111;word-wrap:break-word;word-break:keep-all}

.section_reward .item_flex2{flex:1 0}
.section_reward .item_flex2 .inner_middle{width:100%}
.section_reward .item_flex2 .area_img .inner_img{padding-top:58.8%;background:url(/assets/images/main/img_reward_preview.jpg)no-repeat 0 0;background-size:contain}
.section_reward .item_flex2 .desc_world{margin-top:40px;font-weight:500;font-size:20px;line-height:30px;color:#111}

/* 모바일 */
@media all and (max-width:1199px){
  .section_reward{padding:48px 0}

  .section_reward .area_flex{display:block}
  .section_reward .item_flex1{width:100%;padding:0}
  .section_reward .item_flex1 .tit_world{font-size:28px;line-height:42px}
  .section_reward .item_flex1 .area_img{max-width:538px;margin-right:56px}
  .section_reward .item_flex1 .area_img .inner_img{margin-top:24px;padding-top:132%;background:url(/assets/images/main/img_reward_mo.jpg)no-repeat 0 0;background-size:contain}

  .section_reward .item_flex2{margin-top:24px;text-align:right}
  .section_reward .item_flex2 .desc_world{margin-top:0;font-weight:500;font-size:18px;line-height:27px;color:#666;word-wrap:break-word;word-break:keep-all;text-align:left}
  .section_reward .item_flex2 .desc_world br{display:none}
  .section_reward .item_flex2 .desc_world,
  .section_reward .item_flex2 .area_img{display:inline-block;width:100%;max-width:584px;padding-left:56px;vertical-align:top;box-sizing:border-box}
  .section_reward .item_flex2 .area_img .inner_img{margin-top:24px;padding-top:59%;background:url(/assets/images/main/img_reward_preview_mo.jpg)no-repeat 100% 0;background-size:contain}
}
</style>