import MainModel from '@/views/main/model/MainModel'
import store from '@/store/index';
import {GET_REQUEST, POST_REQUEST} from '@/services/http';
import apiPath from '@/services/apiPath';
import Vue from 'vue'

export default class MainViewModel {
  constructor() {
    this.model = new MainModel();
    this.isMobile = false;
    // 출시알림
    this.alarmData = '';
    this.alarmtermAgree = false;
    this.isAlarmToast = false;
    this.toastData = { show: false, type:'', message:'' };
    this.timeoutToast = null;
    // 팝업
    // this.isPopupTerm = false;
    this.isPopupIosAlarm = false;
    // this.isPopupLetterDetail = false;
    // 스토어 링크
    this.appStoreLink = 'https://apps.apple.com/kr/app/id6448138004';
    this.googleStoreLink = 'https://play.google.com/store/apps/details?id=family.firfin.app&hl=ko';

    this.appDownloadLink = 'https://firfin.page.link/web_landing';
  }
  showMessage(type,message){
    store.dispatch('commonToast/fetchToastStart', {
      toastType : type,
      toastMsg: message
    });
  }
  // onClickTermPopup(){
  //   this.isPopupTerm = true;
  //   fbq('track', 'SubmitApplication');
  //   gtag('event', 'alarmRegisterClick');
  // }
  // onClickCloseTermPopup(){
  //   this.isPopupTerm = false;
  // }
  // onClickLetterDetailPopup(){
  //   this.getLemonLetterLatest();
  // }
  // onClickCloseLetterDetailPopup(){
  //   this.isPopupLetterDetail = false;
  // }
  onClickDownload(PcUserAgent){
    // meta 광고 픽셀
    fbq('track', 'Btn_download_AOSonly');
    // GTM
    gtag('event', 'Btn_download_AOSonly');
    // 네이버 프리미엄 로그 분석
    if (typeof(wcs) != "undefined"){
      if(!wcs_add) var wcs_add = {};
      wcs_add["wa"] = 's_eb4532faab1';
      var _nasa={};
      _nasa["cnv"] = wcs.cnv("5","1");
      wcs_do(_nasa);
    }
    const userAgent = PcUserAgent ? PcUserAgent : Vue.prototype.$varUA;
    // IOS
    if ( userAgent === 'IOS' ) {
      window.open(this.appStoreLink, '_blank');
    // 안드로이드 or ETC
    }else{
      window.open(this.googleStoreLink, '_blank');
    }
  }
  onClickClosePopupIosAlarm(){
    this.isPopupIosAlarm = false;
    this.alarmData = '';
    this.alarmtermAgree = false;
  }
  // top
  // 휴대폰번호 여부 확인
  isPhoneNumberData(){
    const convertedDeleteHyphen = this.alarmData.replaceAll('-', '');
    const regPhoneStart = /^01([0|1|6|7|8|9])-?([0-9]+)$/
    const isPhone = regPhoneStart.test(convertedDeleteHyphen);
    return isPhone;
  }
  onUpdateAlarmData(){
    // 한글입력 제한
    const convertedValue = this.alarmData.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g,'');
    if( convertedValue != this.alarmData ){
      this.alarmData = convertedValue;
      return;
    }
    // 휴대폰번호 자동 하이픈
    const convertedPhoneValue = this.autoHypenPhone(this.alarmData);
    // 휴대폰번호이고 변환할 값과 현재값이 다르면 > 변환
    if(this.isPhoneNumberData() && convertedPhoneValue != this.alarmData){
      this.alarmData = convertedPhoneValue;
      return;
    }
  }
  autoHypenPhone(str){
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if( str.length < 4){
        return str;
    }else if(str.length < 7){
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    }else if(str.length < 11){
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    }else{              
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
    }

    return str;
  }
  onClickAlarmSubmit(){
    // 빈 값일때
    if(!this.alarmData){
      store.dispatch('commonToast/fetchToastStart', {
        toastType : 'error',
        toastMsg: `이메일주소 또는 휴대폰 번호를 다시 한번 확인해 주세요.`
      });
      return;
    }
    // 검증여부
    let validAlarmData = this.validAlarmData(this.alarmData);
    
    // 검증결과 toast
    if( !validAlarmData ){
      store.dispatch('commonToast/fetchToastStart', {
        toastType : 'error',
        toastMsg: `이메일주소 또는 휴대폰 번호를 다시 한번 확인해 주세요.`
      });
      return;
    }else{
      const params = this.setParams(this.alarmData)
      this.requestRegistSurvey(params)
    }
  }
  validAlarmData(value){
    let formatVaild;
    // 휴대폰번호 검증
    if(this.isPhoneNumberData()){
      formatVaild = this.isValidPhone(value);
    // 이메일 검증
    }else{
      formatVaild = this.isValidEmail(value);
    }
    return formatVaild;
  }
  isValidPhone(phone){
    const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    return phone != '' && phone != 'undefined' && regex.test(phone)
  }
  isValidEmail(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return email != '' && email != 'undefined' && regex.test(email)
  }
  setParams(value){
    let params = {};
    // 휴대폰번호 검증
    if(this.isPhoneNumberData()){
      params.phone_number = value;
    // 이메일 검증
    }else{
      params.email = value;
    }
    return params;
  }
  requestRegistSurvey(params){
    const path = apiPath.SURVEY;
    POST_REQUEST(path, params).then((success) => {
      // meta 광고 픽셀
      fbq('track', 'Lead');
      const isPhoneNumber = Boolean(params.phone_number);
      // GTM
      gtag('event', 'alarmRegisterCompelete',{
        'registerType' : isPhoneNumber ? 'phone_number' : 'email'
      });
      // 네이버 프리미엄 로그 분석
      if (typeof(wcs) != "undefined"){
        if(!wcs_add) var wcs_add = {};
        wcs_add["wa"] = 's_eb4532faab1';
        var _nasa={};
        _nasa["cnv"] = wcs.cnv("4","1");
        wcs_do(_nasa);
      }
      store.dispatch('commonToast/fetchToastStart', {
        toastType : 'success',
        toastMsg: `알림이 등록되었어요!`
      });
      this.onClickClosePopupIosAlarm();
    }, (failed) => {
      let errorMsg = `이메일주소 또는 휴대폰 번호를 다시 한번 확인해 주세요.`
      if( failed.errCode === 409 ){
        errorMsg = `입력하신 이메일 혹은 전화번호는 이미 등록되어 있습니다. 다시 입력해주세요.`;
      }
      store.dispatch('commonToast/fetchToastStart', {
        toastType : 'error',
        toastMsg: errorMsg
      });
    })
  }
  getLemonLetterLatest(){
    const path = `${apiPath.LETTER_LATEST}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.letterLastestData = resultData;
      this.isPopupLetterDetail = true;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}