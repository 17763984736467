<template>
  <SectionBasic
    id="card"
    :isActive="isActive">
    <div class="area_middle">
      <div class="inner_middle">
        <h3 class="tit_section">앱으로 충전한 만큼만<br>사용할 수 있어 안전해요</h3>
        <p class="desc_section">벌써부터 카드 만들어줘도 괜찮을까? 걱정마세요!<br>부모가 유연하게 통제가능하기 때문에 안심할 수 있어요.</p>
        <ul class="list_section">
          <li>자녀 계좌 없이 발급 가능</li>
          <li>카드 발급 및 배송비 무료</li>
          <li>보유 한도 20만원, 1회 사용 한도 20만원 제한</li>
          <li>선불교통카드 기능 탑재</li>
          <li>ATM 현금 인출 불가 (현금 사고 방지)</li>
        </ul>
      </div>
    </div>
    <div class="area_parallax_card" ref="areaParallaxCard">
      <div class="inner_parallax_card" :style="{ top: cardTop }"></div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { mapGetters } from 'vuex';

export default {
  name:'SectionCard',
  props:{
    viewModel: Object,
    scrollTop: Number,
    sectionTop: Number,
    sectionBottom: Number,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
  data(){
    return{
      type:''
    }
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowHeight' ]),
    cardTop(){
        // pc
        let limitTop = 40;
        let sectionShowTop = this.getWindowHeight*0;
        let sectionHeight = this.sectionBottom - this.sectionTop;
      if(this.viewModel.isMobile){
        // mobile
        limitTop = 0;
        sectionShowTop = this.getWindowHeight*0.4;
        sectionHeight = 352;
      }
      const startScrollTopPonit = this.sectionTop - this.getWindowHeight + sectionShowTop;
      // show in
      if(this.scrollTop > startScrollTopPonit && this.scrollTop <= this.sectionBottom){
        let goToTop = ( sectionHeight - (this.scrollTop - startScrollTopPonit) ) * 0.6;
        if(goToTop <= limitTop){
          goToTop = limitTop
        }
        this.type = 'show in';
        return goToTop + 'px'
      // show out
      }else if(this.scrollTop > this.sectionBottom){
        this.type = 'show out';
        return limitTop + 'px'
      // before show
      }else{
        this.type = 'before show';
        return '100%';
      }
    },
  }
}
</script>

<style scoped>
.section_card{position:relative;overflow:hidden;padding:40px 0;background-color:#000;color:#fff}
.section_card .area_middle{height:680px}
.section_card .tit_section{font-weight:700;font-size:48px;line-height:64px;color:#fff}
.section_card .desc_section{color:#999}
.section_card .list_section li{color:#999;word-wrap:break-word;word-break:keep-all}
.section_card .area_parallax_card{position:absolute;top:0;bottom:0;left:50%;width:680px;margin-left:-40px}
.section_card .inner_parallax_card{position:absolute;right:0;width:680px;height:680px;background:url(/assets/images/main/img_card.png) no-repeat 0 0;background-size:100%;transition:top 0.6s ease-out}

/* 모바일 */
@media all and (max-width:1199px){
  .section_card{padding:48px 0}
  .section_card .area_middle{float:none;height:auto}
  .section_card .area_middle .inner_middle{display:block}
  .section_card .area_middle .inner_middle .tit_section{font-size:28px;line-height:42px}
  .section_card .area_middle .inner_middle .desc_section{position:relative;z-index:2;margin-top:352px;padding-top:32px;background-image: linear-gradient(transparent, #000 26px 100%)}
  .section_card .list_section li{color:#999}
  .section_card .area_parallax_card{overflow:hidden;position:absolute;top:156px;bottom:inherit;left:0;right:0;float:none;width:100%;height:352px;margin:0}
  .section_card .inner_parallax_card{position:absolute;right:0;width:100%;height:320px;background:url(/assets/images/main/img_card_mobile.png) no-repeat center;background-size:auto 100%;transition:top 0.6s ease-out}
}
</style>