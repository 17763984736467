<template>
  <SectionBasic
    id="world"
    :isActive="isActive">
    <div class="area_overflow">
      <div class="area_left">
        <span class="badge_info">
          <span class="icon_badge icon_badge_world"></span>
          <span class="txt_badge">퍼핀월드</span>
        </span>
        <h3 class="tit_world">생활 속 필수금융지식을<br>게임처럼 재미있게 익혀요</h3>
        <p class="desc_world">퍼핀월드의 은행, 마트, 회사 등 30여개 가상 장소에서 <br>생활밀착형 금융퀴즈를 풀 수 있어요.</p>
      </div>
      <div class="area_overflow">
        <ul class="list_card">
          <li>
            <strong class="tit_card">생활밀착형 <span class="txt_break_mb">금융퀴즈</span></strong>
            <p class="desc_card">교과서적 지식을 <span class="txt_break_mb">넘어 현실적</span> <span class="txt_break_mb">경제관념 형성을</span> 위한 문제 제공</p>
          </li>
          <li>
            <strong class="tit_card">2000+ <span class="txt_break_mb">퀴즈</span></strong>
            <p class="desc_card">30여개 <span class="txt_break_mb">장소에서 필요한</span> <span class="txt_break_mb">기초 상식을</span> <br>퀴즈로 구현</p>
          </li>
        </ul>
        <ul class="list_card">
          <li>
            <strong class="tit_card">개인금융에서 <span class="txt_break_mb">세계경제까지</span></strong>
            <p class="desc_card">15단계 레벨로 <span class="txt_break_mb">설계된 체계적인</span> <span class="txt_break_mb">경제금융학습</span> 커리큘럼</p>
          </li>
          <li>
            <strong class="tit_card">시나리오 <span class="txt_break_mb">게임형 구조</span></strong>
            <p class="desc_card">퀴즈를 <span class="txt_break_mb">해결할수록 도시</span> <span class="txt_break_mb">건설이 완성되는</span> <br>게임 구조</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="area_img"></div>
    <div class="area_phone"></div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionWorld',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_world{padding:120px 0 38px;background-color:#F1EEE1}
.section_world .area_left{padding:6px 40px 0 0}
.section_world .tit_world{font-weight:700;font-size:48px;line-height:64px;color:#111}
.section_world .desc_world{margin-top:24px;font-weight:500;font-size:20px;line-height:30px;color:#111;word-wrap:break-word;word-break:keep-all}
.section_world .list_card{width:100%;text-align:right}
.section_world .list_card li{display:inline-block;width:calc(50% - 12px);padding:24px;background-color:#fff;text-align:left;box-sizing:border-box;vertical-align:top}
.section_world .list_card li + li{margin-left:24px}
.section_world .list_card + .list_card{margin-top:24px}
.section_world .list_card .tit_card{display:block;font-weight:700;font-size:20px;line-height:24px}
.section_world .list_card .desc_card{margin-top:12px;font-weight:500;font-size:16px;line-height:24px;word-wrap:break-word;word-break:keep-all}
.section_world .area_img{width:100%;height:970px;margin-top:64px;background:url(/assets/images/main/img_world.png) no-repeat 0 0;background-size:100%}

/* 모바일 */
@media all and (max-width:1199px){
  .section_world{padding:48px 0}
  .section_world .area_left{float:none;padding:0}
  .section_world .tit_world{font-size:28px;line-height:42px}
  .section_world .desc_world{margin-top:24px;font-weight:700;font-size:18px;line-height:27px;color:#666}
  .section_world .desc_world br{display:none}
  .section_world .list_card{display:flex;flex-wrap:nowrap;margin-top:24px}
  .section_world .list_card li{max-width:none}
  .section_world .list_card li + li{margin-left:16px}
  .section_world .list_card + .list_card{margin-top:16px}
  .section_world .list_card .tit_card{font-size:20px;line-height:24px;word-wrap:break-word;word-break:keep-all}
  .section_world .list_card .desc_card{margin-top:12px;font-weight:500;font-size:16px;line-height:24px}
  .section_world .list_card .desc_card br{display:none}
  .section_world .area_img{width:100%;height:320px;margin-top:24px;background:url(/assets/images/main/img_world_mobile_bg.png) no-repeat 50% 0;background-size:320px 320px}
  .section_world .area_phone{width:188px;height:399px;margin:-262px auto 0;border-radius:27px;background:url(/assets/images/main/img_world_mobile_phone.png) no-repeat 0 0;background-size:188px 399px;box-shadow:30px 30px 90px rgba(0, 0, 0, 0.24);}
  /* drop-shadow(30px 30px 90px rgba(0, 0, 0, 0.24)); */
}
/* 모바일 */
@media all and (max-width:359px){
  .section_world .area_overflow{overflow:visible}
  .section_world .list_card{width:auto;margin:24px -20px 0}
  .section_world .list_card li{max-width:none}
}
</style>