<template>
  <div :class="['check_comm', { checked : input.length > 0 }, { disabled : isDisabled }]">
    <input type="checkbox" 
      :id="id" 
      :value="id" 
      v-model="input" 
      :disabled="isDisabled">
    <label :class="{ir_pm : text == undefined}" :for="id">
      <span class="txt_checkbox"><slot/>{{ text }}</span>
    </label>
    <IconSvg iconName="check" size="24" iconColor="#C4C2C2"/>
  </div>
</template>

<script>
import CheckboxBase from './CheckboxBase'
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'CheckboxOnlyOne',
  components: {
    CheckboxBase,
    IconSvg
  },
  props: {
    id: String,
    value: Boolean,
    text: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    input: {
      get() {
        return this.value ? [ this.id ] : [];
      },
      set(val) {
        const result = val.length > 0;
        this.$emit("update:value", result);
      }
    }
  }
}
</script>

<style scoped>
.check_comm{position:relative;border-radius:4px;border:1px solid #E7E5E5;vertical-align:top}
.check_comm input{position:absolute;top:0;bottom:0;left:0;right:0;z-index:10;width:100%;height:100%;border:0 none;opacity:0.01;filter:alpha(opacity=1);cursor:pointer}
.check_comm label{display:block;padding:16px 0 16px 49px;line-height:24px}
.check_comm .txt_checkbox{display:block;font-weight:600;font-size:16px;color:#333;letter-spacing:-0.02em;word-break:break-all}
.check_comm .ir_pm{padding-left:0}
.check_comm .icon_check{position:absolute;top:16px;bottom:0;left:16px;width:24px;height:24px;z-index:1;border-radius:4px;font-size:0;white-space:nowrap;box-sizing:border-box;text-align:center}
.check_comm .icon_check:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.check_comm .icon_check svg{display:inline-block;vertical-align:middle;white-space:normal}

/* hover */
/* .check_comm input:hover + label .txt_checkbox{color:#8F69DD} */

/* cheked */
.check_comm.checked{border-color:#BEA9ED;background-color:#F0EAFA}
.check_comm.checked .icon_check >>> g{fill:#8F69DD}

/* disabled */
.check_comm input:disabled,
.check_comm input:disabled:checked{cursor:auto}
.check_comm input:disabled + label .txt_checkbox{color:#888}
.check_comm input:disabled + label + .icon_check{border:1px solid #bbb}
.check_comm input:disabled:checked + label + .icon_check g{fill:#888}

.check_comm + .check_comm{margin-left:32px}

</style>
