<template>
  <SectionBasic
    id="review"
    :isActive="isActive">
    <h3 class="screen_out">퍼핀 사용자 후기</h3>
    <swiper
      class="swiper_review"
      :options="swiperReviewOption"
      ref="reviewSwiper">
      <swiper-slide v-for="(item,index) in viewModel.model.reviewDataList" :key="'reviewSwiper'+index">
        <div class="item_review area_middle">
          <div class="inner_middle">
            <div class="star_review">
              <span
                v-for="index in 5"
                :key="`star${index}`"
                :class="[
                  'icon_star',
                  { 'icon_star_fill' : index <= item.reviewScore }
                ]"></span>
            </div>
            <p class="desc_review" v-html="item.review"></p>
            <div :class="['logo_review_from',`logo_${item.reviewFrom.toLowerCase()}`]"></div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination swiper-review-pagination" slot="pagination">{{ isInit }}</div>
    </swiper>
    <button class="swiper-review-button-prev">
      <IconSvg
        iconName="page_prev"
        iconColor="#232323"
        :size="12"/>
      <span class="screen_out">이전 페이지 보기</span>
    </button>
    <button class="swiper-review-button-next">
      <IconSvg
        iconName="page_next"
        iconColor="#232323"
        :size="12"/>
      <span class="screen_out">다음 페이지 보기</span>
    </button>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { Swiper, SwiperSlide, Navigation, Pagination } from 'vue-awesome-swiper'
import IconSvg from '@/components/common/icon/IconSvg.vue';

export default {
  name:'SectionReview',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
    Swiper,
    SwiperSlide,
    Navigation,
    Pagination,
    IconSvg,
  },
  mounted(){
    this.$nextTick(() => {
      if(this.$refs.reviewSwiper && this.$refs.reviewSwiper.$swiper){
        this.isInit = true;
      }
    });
  },
  data(){
    return {
      isInit: false,
      swiperReviewOption: {
        autoplay: {
          delay: 3000
        },
        loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 26,
        centeredSlides: false,
        speed:800,
        mousewheel:{
          forceToAxis:true,
        },
        pagination: {
          el: '.swiper-review-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation:{
          enabled: true,
          prevEl: '.swiper-review-button-prev',
          nextEl: '.swiper-review-button-next',
        },
        breakpoints:{
          1200:{
            slidesPerView: 3,
            slidesPerGroup: 3,
            centeredSlides: false,
          }
        }
      },
    }
  }
}
</script>

<style scoped>
.section_review{padding:120px 0;background-color:#F1EEE1}
.section_review >>> .inner_section{position:relative}
.section_review .swiper_review{margin:0 74px;padding-bottom:40px}
.section_review .swiper_review >>> .swiper-wrapper{align-items:stretch}
.section_review .swiper_review >>> .swiper-slide{height:auto}
.section_review .item_review{display:inline-block;width:362px;height:100%;padding:42px 0;border-radius:4px;background-color:#fff;vertical-align:top;text-align:center;box-sizing:border-box}

.section_review .item_review .star_review .icon_star{display:inline-block;width:20px;height:20px;vertical-align:top}
.section_review .item_review .star_review .icon_star_fill{background:url(/assets/images/main/icon_star_fill.png) no-repeat 0 0;background-size:contain}
.section_review .item_review .star_review .icon_star + .icon_star{margin-left:6px}

.section_review .item_review .desc_review{margin-top:20px;font-weight:600;font-size:20px;line-height:30px;color:#111;word-wrap:break-word;word-break:keep-all}

.section_review .item_review .logo_review_from{margin:20px auto 0}
.section_review .item_review .logo_google_play{width:108px;height:22px;background:url(/assets/images/main/img_review_google_play.png)no-repeat 0 0;background-size:contain}
.section_review .item_review .logo_instagram{width:108px;height:22px;background:url(/assets/images/main/img_review_instagram.png)no-repeat 0 0;background-size:contain}
.section_review .item_review .logo_naver_mom_cafe{width:108px;height:22px;background:url(/assets/images/main/img_review_naver_mom_cafe.png)no-repeat 0 0;background-size:contain}

.section_review .swiper_review >>> .swiper-pagination-bullets{height:8px;bottom:0}
.section_review .swiper_review >>> .swiper-pagination-bullet{width:8px;height:8px;opacity:1;background-color:#fff;vertical-align:top}
.section_review .swiper_review >>> .swiper-pagination-bullet-active{background-color:#232323}

.section_review .swiper-review-button-prev,
.section_review .swiper-review-button-next{position:absolute;top:50%;z-index:10;width:48px;height:48px;margin-top:-40px;padding:18px;border-radius:100%;background-color:#E2D7C4}
.section_review .swiper-review-button-prev{left:20px}
.section_review .swiper-review-button-next{right:20px}

/* 모바일 */
@media all and (max-width:1199px){
  .section_review{padding:16px 0}
  .section_review .swiper_review{margin:0 32px;padding-bottom:22px}
  .section_review .item_review{width:100%;padding:24px 14px;}
  .section_review .item_review .txt_mark{font-weight:600;font-size:16px;line-height:24px}
  .section_review .item_review .desc_review{margin-top:10px;font-size:16px;line-height:24px}
  .section_review .item_review .logo_review_from{margin-top:10px}
  .section_review .swiper_review >>> .swiper-pagination-bullets{height:6px}
  .section_review .swiper_review >>> .swiper-pagination-bullet{width:6px;height:6px}
  .section_review .swiper-review-button-prev,
  .section_review .swiper-review-button-next{top:50%;width:24px;height:24px;margin-top:-22px;padding:6px}
}
</style>