<template>
  <div class="check_comm">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'CheckboxBase'
}
</script>

<style scoped>
.check_comm{display:inline-block;position:relative;min-width:22px;min-height:30px;vertical-align:top}
.check_comm >>> input{position:absolute;top:0;bottom:0;left:0;right:0;z-index:10;width:100%;height:100%;border:0 none;opacity:0.01;filter:alpha(opacity=1);cursor:pointer}
.check_comm >>> label{display:block;padding:5px 0 5px 35px;line-height:20px}
.check_comm >>> .txt_checkbox{display:block;font-weight:800;font-size:16px;color:#333;letter-spacing:-0.02em;word-break:break-all}
.check_comm >>> .ir_pm{padding-left:0}
.check_comm >>> .icon_check{position:absolute;top:-2px;bottom:0;left:0;width:24px;height:24px;z-index:1;margin:auto;border:2px solid #ccc;border-radius:4px;font-size:0;background-color:#fff;white-space:nowrap;box-sizing:border-box;text-align:center}
.check_comm >>> .icon_check:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.check_comm >>> .icon_check svg{display:inline-block;vertical-align:middle;white-space:normal}

/* hover */
.check_comm >>> input:hover + label .txt_checkbox{color:#3DBF69}

/* cheked */
.check_comm >>> input:checked + label + .icon_check g{fill:#3DBF69}
.check_comm >>> input:checked + label + .icon_check{border-color:#3DBF69}

/* disabled */
.check_comm >>> input:disabled,
.check_comm >>> input:disabled:checked{cursor:auto}
.check_comm >>> input:disabled + label .txt_checkbox{color:#888}
.check_comm >>> input:disabled + label + .icon_check{border:1px solid #bbb}
.check_comm >>> input:disabled:checked + label + .icon_check g{fill:#888}

.check_comm + .check_comm{margin-left:32px}

.check_comm_large{display:block;height:50px;min-height:inherit}
.check_comm_large >>> label{padding:13px 10px 11px 48px;border:1px solid #e1e1e1;border-radius:4px;font-weight:normal;font-family:'NotoSansKR_M', sans-serif;font-size:14px;line-height:24px;color:#555}
.check_comm_large >>> .icon_check{left:14px;margin-top:12px}
.check_comm_large >>> input:checked + label{border:1px solid #555;color:#222}
</style>