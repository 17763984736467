<template>
  <SectionBasic
    id="finance"
    :isActive="isActive">
    <h3 class="screen_out">퍼핀 기능 소개</h3>
    <ul class="list_card">
      <li>
        <span class="icon_card icon_free"></span>
        <p class="desc_card">용돈 정기·수시 송금 <span class="txt_break_mb">수수료 전액 무료</span></p>
      </li>
      <li>
        <span class="icon_card icon_balance"></span>
        <p class="desc_card">잔액 및 소비내역 <span class="txt_break_mb">실시간 조회</span></p>
      </li>
    </ul>
    <ul class="list_card">
      <li>
        <span class="icon_card icon_alert"></span>
        <p class="desc_card">이상거래탐지 시 <span class="txt_break_mb">부모에게 알림</span></p>
      </li>
      <li>
        <span class="icon_card icon_reward"></span>
        <p class="desc_card">금융 퀴즈와 <span class="txt_break_mb">학습 보상 시스템</span></p>
      </li>
    </ul>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionFinance',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_finance{padding:120px 0;background-color:#F1EEE1}
.section_finance .list_card{display:flex;flex-wrap:nowrap}
.section_finance .list_card li{display:inline-block;width:calc(50% - 20px);padding:32px;border-radius:8px;background-color:#fff;box-sizing:border-box}
.section_finance .list_card li + li{margin-left:40px}
.section_finance .list_card + .list_card{margin-top:40px}
.section_finance .list_card li .icon_free{background-image:url(/assets/images/main/img_finance_free.png)}
.section_finance .list_card li .icon_balance{background-image:url(/assets/images/main/img_finance_balance.png)}
.section_finance .list_card li .icon_alert{background-image:url(/assets/images/main/img_finance_alert.png)}
.section_finance .list_card li .icon_reward{background-image:url(/assets/images/main/img_finance_reward.png)}
.section_finance .list_card li .icon_card{display:block;width:48px;height:48px;background-size:100%;background-repeat:no-repeat}
.section_finance .list_card li .desc_card{margin-top:24px;font-weight:600;font-size:24px;line-height:36px;word-wrap:break-word;word-break:keep-all}

/* 모바일 */
@media all and (max-width:1199px){
  .section_finance{padding:48px 0}
  .section_finance .list_card li{width:calc(50% - 8px);padding:16px}
  .section_finance .list_card li + li{margin-left:16px}
  .section_finance .list_card + .list_card{margin-top:16px}
  .section_finance .list_card li .icon_card{width:40px;height:40px}
  .section_finance .list_card li .desc_card{margin-top:16px;font-weight:500;font-size:14px;line-height:22px}
}
</style>