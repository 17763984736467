<template>
  <SectionBasic
    id="safe"
    :isActive="isActive">
    <div class="area_overflow_after">
      <div class="area_right">
        <!-- <span class="badge_info">
          <span class="icon_badge icon_badge_safe"></span>
          <span class="txt_badge">전자금융업 등록완료</span>
        </span> -->
        <h3 class="tit_section">퍼핀의 정보보안체계는<br>믿을만하고 안전합니다</h3>
        <p class="desc_section">금융위원회에서 인증받은 전자금융업 사업자로서 견고하고 강력한<br> 정보보안체계로 우리 가족의 금융 생활을 안전하게 지켜요</p>
        <ul class="list_section">
          <li>개인정보와 금융정보의 불법 유출 방지</li>
          <li>데이터 이동 및 보관 시 암호화</li>
          <li>보안을 최우선으로 고려한 개발 인프라 구축</li>
        </ul>
      </div>
      <div class="area_left">
        <div class="area_phone">
          <div class="img_phone"></div>
        </div>
        <div class="img_shield"></div>
      </div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionSafe',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_safe{padding:160px 0;background-color:#fff}
.section_safe >>> .inner_section{position:relative}
.section_safe .tit_section{font-weight:700;font-size:48px;line-height:64px;color:#111}
.section_safe .desc_section{color:#111}
.section_safe .list_section li{color:#666}
.section_safe .area_left{width:620px;background:url(/assets/images/main/img_safe_bg.png) no-repeat 28px 116px;background-size:564px}
.section_safe .area_phone{width:302px;height:672px;margin:40px 0 40px 145px;padding:14px;border-radius:47.5px;box-shadow: 30px 30px 90px rgba(0, 0, 0, 0.24);background-color:#fff}
.section_safe .area_phone .img_phone{width:100%;height:100%;border-radius:35.5px;background:url(/assets/images/main/img_safe_phone.jpg) no-repeat 0 0;background-size:100%}
.section_safe .img_shield{position:absolute;bottom:9px;left:352px;width:190px;height:230px;background:url(/assets/images/main/img_safe_shield.png) no-repeat 0 0;background-size:100%}
.section_safe .area_right{padding:199px 81px 0 0}

/* 모바일 */
@media all and (max-width:1199px){
  .section_safe{padding:48px 0;background-color:#fff}
  .section_safe >>> .inner_section{position:relative}
  .section_safe .area_overflow{overflow:visible}
  .section_safe .area_left,
  .section_safe .area_right{float:none}
  .section_safe .area_left{width:100%;max-width:320px;padding-bottom:14px;margin:24px auto 0;background:url(/assets/images/main/img_safe_bg_mo.png) no-repeat 0 45px;background-size:100% auto}
  .section_safe .area_phone{width:172px;height:382px;margin:0 auto 0;padding:8px;border-radius:31.2px;box-shadow:10px 10px 30px rgba(0, 0, 0, 0.24)}
  .section_safe .area_phone .img_phone{width:100%;height:100%;border-radius:23.5px;background-size:100%}
  .section_safe .img_shield{bottom:0;left:50%;width:106px;height:130px;margin-left:24px;background:url(/assets/images/main/img_safe_shield_mo.png) no-repeat 0 0;background-size:100%}

  .section_safe .tit_section{font-size:28px;line-height:42px}
  .section_safe .desc_section{color:#666}
  .section_safe .desc_section br{display:none}
  .section_safe .area_right{padding:0}
}
</style>