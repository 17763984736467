<template>
  <SectionBasic
    id="content"
    :isActive="isActive">
    <span class="txt_section">금융 세계에 온 걸 환영해요!</span>
    <h3 class="tit_section">어디에서도 볼 수 없는 <span class="txt_break_response">체계적인 금융 학습 콘텐츠</span><br>퍼핀월드</h3>
    <div
      :class="[
        'area_video',
        `area_video_type_${windowRatioType}`,
        isPaused ? 'video_paused' : 'video_play'
      ]"
      :style="cssProps">
      <div class="inner_video">
        <video
          ref="videoContent"
          width="100%"
          height="100%"
          muted loop playsinline
          preload="none"
          :poster="videoPoster[windowRatioType]">
          <source
            ref="videoContentSource"
            :src="videoSrc[windowRatioType]"
            type="video/mp4">
        </video>
      </div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { mapGetters } from 'vuex';

export default {
  name:'SectionContent',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
  data(){
    return{
      videoPoster:{
        pc:'/assets/images/main/img_indroduce_poster.png',
        mo:'/assets/images/main/img_indroduce_poster_mo.png',
      },
      videoSrc:{
        pc:'/assets/video/vedio_firfin_introduce_after_release.mp4',
        mo:'/assets/video/vedio_firfin_introduce_after_release_mo.mp4'
      },
      isPaused: true
    }
  },
  watch:{
    isActive(){
      if(this.isActive){
        this.videoReStart()
      }
    },
    getIsMobile(newVal, oldVal){
      if(newVal != oldVal){
        this.videoReStart()        
      }
    },
    windowRatioType(newVal, oldVal){
      if(newVal != oldVal){
        this.videoReStart()        
      }
    },
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowWidth','getWindowHeight','getIsMobile' ]),
    windowRatioType(){
      if(!this.getIsMobile){
        return 'pc'
      }else{
        return this.getWindowWidth >= this.getWindowHeight ? 'pc' : 'mo'
      }
    },
    cssProps(){
      const windowWidth = this.getWindowWidth > 1280 ? 1280 : this.getWindowWidth
      const maxWidthToHeightPc = (windowWidth - 40)*0.5625;
      const height70 = this.getWindowHeight*0.7;
      const maxWidthToHeightMo = (windowWidth - 40)*1.77;
      const height80 = this.getWindowHeight*0.8;
      var pcPaddingTop = maxWidthToHeightPc < height70 ? maxWidthToHeightPc : height70;
      var moPaddingTop = maxWidthToHeightMo < height80 ? maxWidthToHeightMo : height80;
      return {
        '--pcPaddingTop': `${pcPaddingTop}px`,
        '--moPaddingTop': `${moPaddingTop}px`
      }
    }
  },
  methods:{
    videoReStart(){
      this.$nextTick(() => {
        this.$refs.videoContentSource.setAttribute('src', this.videoSrc[this.windowRatioType]);
        this.$refs.videoContent.load();
        this.$refs.videoContent.currentTime = 0;
        this.$refs.videoContent.play();
        this.isPaused = false;
      });
    },
  }
}
</script>

<style scoped>
.section_content{padding:120px 0;background-color:#232323;color:#fff;text-align:center}
.section_content .txt_section{display:block;font-weight:700;font-size:32px;line-height:28px;color:#BEA9ED}
.section_content .tit_section{font-weight:700;font-size:54px;line-height:68px;color:#fff}
.section_content .area_video{width:100%;margin-top:56px}
.section_content .area_video .inner_video{overflow:hidden;position:relative;z-index:2;background-color:#232323}
.section_content .area_video .inner_video video{position:absolute;top:0;left:0;right:0;-webkit-mask-image: -webkit-radial-gradient(white, #232323);-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden}
.section_content .area_video{margin:56px auto 0}
.section_content .area_video.area_video_type_pc{max-width:1280px}
.section_content .area_video.area_video_type_pc .inner_video{padding-top:var(--pcPaddingTop)}
.section_content .area_video.area_video_type_mo{max-width:100%}
.section_content .area_video.area_video_type_mo .inner_video{padding-top:var(--moPaddingTop)}

/* 모바일 */
@media all and (max-width:1199px){
  .section_content{padding:48px 0}
  .section_content .txt_section{font-weight:700;font-size:18px;line-height:27px}
  .section_content .tit_section{font-size:30px;line-height:45px;word-wrap:break-word;word-break:keep-all}
  .section_content .area_video{margin:24px auto 0}
}
/* 모바일 */
@media all and (max-width:359px){
  .section_content >>> .inner_section{padding:0}
}
</style>