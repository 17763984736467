<template>
  <SectionBasic
    id="app"
    :isActive="isActive">
    <h3 class="tit_section">퍼핀 앱 하나로<br>가족이 함께 관리해요</h3>
    <p class="desc_section">부모는 카드사용내역을 <span class="txt_break_response">실시간으로 확인할 수 있고,</span><br>자녀는 돈을 관리하는 방법을 익히며 <span class="txt_break_response">금융지식까지 쌓을 수 있어요.</span></p>
    <div class="list_app_phone">
      <div class="item_app" v-for="(item,index) in viewModel.model.appDataList" :key="'appSwiper'+index">
        <div class="area_phone">
          <div class="img_phone" :style="{ 'background-image':`url(/assets/images/main/${item.img}${viewModel.isMobile ? '_mo' : ''}.jpg)` }" ></div>
        </div>
        <span class="txt_app">{{ item.text }}</span>
      </div>
    </div>
    <div class="app_blur app_blur_left"></div>
    <div class="app_blur app_blur_right"></div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionApp',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_app{overflow:hidden;position:relative;padding:120px 0;background-color:#BEA9ED;text-align:center}
.section_app .tit_section{font-weight:700;font-size:48px;line-height:64px;color:#111}
.section_app .list_app_phone{position:relative;left:50%;width:1842px;height:754px;margin-top:64px;margin-left:-921px;white-space:nowrap}
.section_app .list_app_phone .item_app{display:inline-block;vertical-align:top}
.section_app .list_app_phone .area_phone{width:302px;height:672px;padding:14px;border-radius:47.5px;box-shadow: 30px 30px 90px rgba(0, 0, 0, 0.24);background-color:#fff}
.section_app .list_app_phone .item_app + .item_app{margin-left:48px}
.section_app .list_app_phone .img_phone{width:100%;height:100%;border-radius:35.5px;background-size:100%}
.section_app .list_app_phone .txt_app{display:block;margin-top:30px;font-weight:500;font-size:20px;line-height:24px;color:#666}
.section_app .app_blur{position:absolute;top:0;bottom:0;z-index:10;width:320px}
.section_app .app_blur_left{left:0;background-image: linear-gradient(to right,#BEA9ED,transparent)}
.section_app .app_blur_right{right:0;background-image: linear-gradient(to left,#BEA9ED,transparent)}

/* 모바일 */
@media all and (max-width:1199px){
  .section_app{padding:48px 0 105px}
  .section_app .tit_section{font-size:30px;line-height:45px;word-wrap:break-word;word-break:keep-all}
  .section_app .list_app_phone{width:653px;height:auto;margin-top:24px;margin-left:-357px}
  .section_app .list_app_phone .item_app{display:inline-block;vertical-align:top}
  .section_app .list_app_phone .item_app + .item_app{margin-left:16px}
  .section_app .list_app_phone .area_phone{width:118px;height:261px;padding:6px;border-radius:18.5px;box-shadow:10px 10px 30px rgba(0, 0, 0, 0.24)}
  .section_app .list_app_phone .img_phone{width:100%;height:100%;border-radius:13.8px;background-size:100%}
  .section_app .list_app_phone .txt_app{max-width:130px;margin-top:24px;font-weight:500;font-size:14px;line-height:22px;white-space:normal;word-wrap:break-word;word-break:keep-all}
  .section_app .app_blur{width:20px}
}
</style>