<template>
  <SectionBasic
    id="outro"
    :isActive="isActive">
    <h3 class="tit_section">자녀의 소비, 저축, <span class="txt_break_response">투자교육까지</span><br>퍼핀과 함께 <span class="txt_break_response">즐겁고 안전한</span> <span class="txt_break_mb">가족 금융을 시작해보세요</span></h3>
    <div class="area_img">
      <div class="inner_img"></div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionOutro',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_outro{padding:120px 0;background-color:#8F69DD;text-align:center;word-wrap:break-word;word-break:keep-all}
.section_outro .tit_section{font-weight:700;font-size:54px;line-height:68px;color:#111}
.section_outro .area_img .inner_img{width:100%;padding-top:66.6%;margin-top:56px;background:url(/assets/images/main/img_outro.jpg)no-repeat 0 0;background-size:100%}

/* 모바일 */
@media all and (max-width:1199px){
  .section_outro{padding:48px 0}
  .section_outro .tit_section{font-size:30px;line-height:45px}
  .section_outro .area_img{max-width:640px;margin:0 auto}
  .section_outro .area_img .inner_img{width:100%;padding-top:66.6%;margin-top:32px;background:url(/assets/images/main/img_outro_mo.jpg)no-repeat 0 0;background-size:100%}
}
</style>