<template>
  <SectionBasic
    id="partner"
    :isActive="isActive">
    <h3 class="tit_section">최고의 전문가들과 함께 개발한<br> <span class="txt_break_mb">재밌고 유익한</span> <span class="txt_break_mb">금융 학습 콘텐츠</span></h3>
    <p class="desc_section">매일경제 경제경영연구소, 전현직 교사, 자녀경제교육 베스트셀러 저자, 에듀테크 전문가, 글로벌게임기업디렉터까지<br> 아이들에게 어떻게하면 쉽고 재미있게 금융경제지식을 알려줄 수 있을지 고민했어요.</p>
    <div class="area_phone">
      <div class="img_phone" :style="{ 'background-image':`url(/assets/images/main/img_partner.png)` }" ></div>
    </div>
    <swiper
      class="swiper_partner"
      :options="swiperPartnerOption"
      ref="partnerSwiper">
      <swiper-slide v-for="index of 13" :key="'partnerSwiper'+index">
        <div class="item_partner_slide">
          <div class="img_slide" :style="{ 'background-image':`url(/assets/images/main/img_partner_slide${index}${viewModel.isMobile ? '_mo' : ''}.jpg)` }" ></div>
        </div>
      </swiper-slide>
    </swiper>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name:'SectionPartner',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
    Swiper,
    SwiperSlide,
  },
  mounted(){
    this.$nextTick(() => {
      if(this.$refs.partnerSwiper && this.$refs.partnerSwiper.$swiper){
        this.isInit = true;
      }
    });
  },
  data(){
    return{
      isInit: false,
      swiperPartnerOption:{
        allowTouchMove:false,
        autoplay: {
          delay: 0,
          reverseDirection: true,
        },
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 20,
        freeMode: true,
        speed: 3000,
      }
    }
  },
}
</script>

<style scoped>
.section_partner{position:relative;padding:120px 0 396px;background-color:#F1EEE1;text-align:center}
.section_partner >>> .inner_section{max-width:none;padding:0}
.section_partner .tit_section{font-weight:700;font-size:54px;line-height:68px;color:#111}
.section_partner .area_phone{position:absolute;top:420px;left:50%;z-index:10;width:302px;height:672px;margin-left:-165px;padding:14px;border-radius:48px;box-shadow: 30px 30px 90px rgba(0, 0, 0, 0.24);background-color:#fff}
.section_partner .img_phone{width:100%;height:100%;background-size:100%}
.section_partner .swiper_partner{height:228px;margin-top:316px;}
.section_partner .swiper_partner >>> .swiper-wrapper{transition-timing-function : linear}
.section_partner .swiper_partner .swiper-slide{width:228px}
.section_partner .swiper_partner .img_slide{width:228px;height:228px;background-size:100%}

/* 모바일 */
@media all and (max-width:1199px){
  .section_partner{padding:48px 0 173px}
  .section_partner .tit_section{padding:0 20px;font-size:30px;line-height:45px;word-wrap:break-word;word-break:keep-all}
  .section_partner .tit_section br{display:none}
  .section_partner .desc_section{padding:0 20px;font-weight:700;color:#666}
  .section_partner .desc_section br{display:none}
  .section_partner .area_phone{top:inherit;bottom:48px;width:188px;height:399px;margin-left:-94px;padding:8px;border-radius:27px;box-shadow:10px 10px 30px rgba(0, 0, 0, 0.24);box-sizing:border-box}
  .section_partner .swiper_partner{height:148px;margin-top:158px}
  .section_partner .swiper_partner >>> .swiper-wrapper{transition-timing-function : linear}
  .section_partner .swiper_partner .swiper-slide{width:128px;height:128px;padding:10px;background-color:#fff}
  .section_partner .swiper_partner .img_slide{width:128px;height:128px;background-size:100%}
}
</style>