import { render, staticRenderFns } from "./SectionReview.vue?vue&type=template&id=14a0a82a&scoped=true&"
import script from "./SectionReview.vue?vue&type=script&lang=js&"
export * from "./SectionReview.vue?vue&type=script&lang=js&"
import style0 from "./SectionReview.vue?vue&type=style&index=0&id=14a0a82a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a0a82a",
  null
  
)

export default component.exports